<template>
  <div class="season-wrap">
    <headNav active="2"></headNav>
    <div
      class="season-title cyellow"
      :style="{
        backgroundImage:
          'url(' +
          require('../assets/season/' +
            ($i18n.locale == 'zh' ? 'music-1' : 'musicEn-1') +
            '.png') +
          ')',
      }"
    >
      <div class="time-line">
        <div class="flex between" style="width: 1200px; margin: 0 auto">
          <div
            class="item"
            :class="currentIndex == 1 ? 'active' : ''"
            @click="changeYear(1)"
          >
            2020-2021
            <div class="circle cir1"></div>
          </div>
          <div
            class="item"
            :class="currentIndex == 2 ? 'active' : ''"
            @click="changeYear(2)"
          >
            2021-2022
            <div class="circle cir2"></div>
          </div>
            <div
            class="item"
            :class="currentIndex == 3 ? 'active' : ''"
            @click="changeYear(3)"
          >
            2022-2023
            <div class="circle cir3"></div>
          </div>
            <div
            class="item"
            :class="currentIndex == 4 ? 'active' : ''"
            @click="changeYear(4)"
          >
            2023-2024
            <div class="circle cir4"></div>
          </div>
          <div
            class="item"
            :class="currentIndex == 5 ? 'active' : ''"
            @click="changeYear(5)"
          >
            2024-2025
            <div class="circle cir5"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="season-contents">
      <div class="dot dot-1"></div>
      <div v-if="currentIndex == 1" class="w1200">
        <div class="item tac" v-for="(item, key) in 19" :key="key">
          <img
            v-lazy="require(`../assets/season/season-${key + 1}.png`)"
            alt=""
            class="wd"
          />
        </div>
      </div>
      <div v-if="currentIndex == 2" class="w1200">
        <div v-if="$i18n.locale == 'zh'">
          <div class="item tac" v-for="(item, key) in 11" :key="key">
            <img
              v-lazy="require(`../assets/season/season2-${key + 1}.png`)"
              alt=""
              class="wd"
            />
          </div>
        </div>
        <div v-else>
          <div class="item tac" v-for="(item, key) in 8" :key="key">
            <img
              v-lazy="require(`../assets/season/seasonEn2-${key + 1}.png`)"
              alt=""
              class="wd"
            />
          </div>
        </div>
      </div>
      <div v-if="currentIndex == 3" class="w1200">
        <div class="item1 tac" v-for="(item, key) in 18" :key="key">
          <img
            v-lazy="require(`../assets/season/season3-${key + 1}.png`)"
            alt=""
            class="wd"
          />
        </div>
      </div>
      <div v-if="currentIndex == 4" class="w1200">
        <div class="item1 tac" v-for="(item, key) in 19" :key="key">
          <img
            v-lazy="require(`../assets/season/season4-${key + 1}.png`)"
            alt=""
            class="wd"
          />
        </div>
      </div>
      <div v-if="currentIndex == 5" class="w1200">
        <div class="item1 tac" v-for="(item, key) in 14" :key="key">
          <img
            v-lazy="require(`../assets/season/season5-${key + 1}.jpg`)"
            alt=""
            class="wd"
          />
        </div>
      </div>
    </div>
    <footNav />
  </div>
</template>

<script>
import headNav from "../components/header2.vue";
import footNav from "../components/footer2.vue";
export default {
  components: { headNav, footNav },
  data() {
    return {
      currentIndex: 5,
    };
  },
  methods: {
    changeYear(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style scoped lang="scss">
.season-wrap {
  background-color: #f2f6f7;
}
.season-title {
  width: 100%;
  height: 770px;
  background-repeat: no-repeat;
  padding-top: 610px;
  background-size: auto 100%;
  background-position: center center;

  .time-line {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .item {
      width: 300px;
      height: 84px;
      line-height: 84px;
      text-align: center;
      position: relative;
      font-size: 30px;
      font-family: DIN, DIN-Regular;
      font-weight: 400;
      top: -100px;
      color: #fff;
      cursor: pointer;
      &::before {
        position: absolute;
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: #27E7D6;
        border-radius: 50%;
        bottom: -27px;
        left: 140px;
        z-index: 11;
      }
      &.active {
        font-size: 38px;
        font-weight: 400;
        color: #fff;
        text-shadow: 0px 1px 9px rgba(164, 113, 77, 0.3);
        .circle {
          position: absolute;
          bottom: -32px;
          left: 134px;
          width: 32px;
          height: 32px;
          background: rgba(39, 231, 214, 0.4);
          border-radius: 50%;
          z-index: 10;
          &::before {
            position: absolute;
            left: 16px;
            top: 33px;
            content: "";
            display: block;
            width: 0;
            // height: 135px;
            border-right: 1px dashed #27E7D6;
          }
        }
        .cir1{
          &::before {
            height: 118px;
          }
        }
        .cir2{
          &::before {
            height: 137px;
          }
        }
        .cir3{
          &::before {
            height: 142px;
          }
        }
        .cir4{
          &::before {
            height: 134px;
          }
        }
        .cir5{
          &::before {
            height: 110px;
          }
        }
      }
    }
    &::before {
      position: absolute;
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      left: 0;
      background: #fff;
    }
  }
}

.season-contents {
  position: relative;
  padding-top: 106px;
  .dot {
    position: absolute;
    z-index: 99;
    background-image: url("../assets/version2/other-13.png");
    background-size: 100% 100%;
  }
  .dot-1 {
    width: 470px;
    height: 821px;
    top: -150px;
    left: 0;
  }
  .item {
    margin-bottom: 95px;
  }
   .item1 {
    margin-bottom: 25px;
  }
}
</style>
